import { Patient } from "../../types/models";
import React from "react";
import { useLabSummaries } from "../../hooks/patientHooks";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function LabSummary(props: { patient: Patient | undefined }) {
  const summary = useLabSummaries(props.patient?.id);
  return (
    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300 border-collapse print:border print:border-gray-500">
          <caption className="screen:hidden print:visible">Lab Summary</caption>
          <thead className="bg-slate-300 dark:bg-slate-500">
            <tr>
              <th className="py-3.5 pl-4 pr-3 border print:border-gray-500 text-left text-sm print:text-xs font-semibold text-gray-900 dark:text-white">
                Category
              </th>
              <th className="py-3.5 pl-4 pr-3 border print:border-gray-500 text-left text-sm print:text-xs font-semibold text-gray-900 dark:text-white">
                Lab
              </th>
              {summary.encounter_dates.map((the_date: any, index: number) => (
                <th className="py-3.5 pl-4 pr-3 border print:border-gray-500 text-left text-sm print:text-xs font-semibold text-gray-900 dark:text-white">
                  {the_date}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {summary.summaries.map((lab: any) => (
              <tr>
                <td
                  className={classNames(
                    lab.color,
                    "py-3.5 pl-4 pr-3 print:text-xs  border print:border-gray-500 text-gray-900 dark:text-white"
                  )}
                >
                  {lab.category}
                </td>
                <td
                  className={classNames(
                    lab.color,
                    "py-3.5 pl-4 pr-3 print:text-xs  border print:border-gray-500 text-gray-900 dark:text-white"
                  )}
                >
                  {lab.description}
                </td>
                {lab.values.map((value: any) => (
                  <td
                    className={classNames(
                      lab.color,
                      "py-3.5 pl-4 pr-3 print:text-xs  border print:border-gray-500 text-gray-900 dark:text-white"
                    )}
                  >
                    {value.v == null ? "" : value.v}{" "}
                    {lab.unit == null || value.v === 0 || value.v === null
                      ? ""
                      : lab.unit}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
