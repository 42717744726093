import { DataSeries, Encounter } from "../../types/models";
import React from "react";

import BubbleChart from "../widgets/BubbleChart";

export default function Encounters(props: { series: DataSeries[] }) {
  const rowDescription = (encounter: Encounter): string => {
    return `${encounter.patient_class} - ${encounter.encounter_type} - ${encounter.reason}`;
  };
  return (
    <BubbleChart
      name={"Encounters"}
      rowDescription={rowDescription}
      rows={props.series}
    />
  );
}
