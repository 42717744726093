import React, { useEffect, useState } from "react";
import { UITask } from "../../hooks/taskHooks";
import { apiRequest } from "../../services/baseApi";
import { reassignTask, updateTask } from "../../services/taskApi";
import { Task } from "../../types/fhir";
import { useMsal } from "@azure/msal-react";
import Timestamp from "../widgets/Timestamp";
import { isCareManagerAdmin } from "../../hooks/userHooks";
import Assignment from "./Assignment";
import { TaskOwner } from "../../types/models";
import PatientLink from "../widgets/PatientLink";
import { useQueryClient } from "@tanstack/react-query";
import Loading from "../widgets/Loading";

class DataItem {
  name: string;
  value: string | React.ReactNode;
  link?: string;

  constructor(name: string, value: string | React.ReactNode, link?: string) {
    this.name = name;
    this.value = value;
    this.link = link;
  }
}

export default function TaskDetails(props: {
  task: UITask | undefined;
  setLastModified: (
    value: ((prevState: string | undefined) => string) | string
  ) => void;
  lastModified: string | undefined;
  loading: boolean;
}) {
  const task = props.task! as UITask;
  const authContext = useMsal();
  const queryClient = useQueryClient();
  const isAdmin = isCareManagerAdmin(authContext);
  const setLastModified = props.setLastModified;
  const [status, setStatus] = useState(task?.status);
  const [taskOwner, setTaskOwner] = useState<TaskOwner | null>(
    props.task
      ? {
          owner: props.task!.owner,
          owner_name: props.task!.ownerName,
        }
      : null
  );
  function saveStatus(stat: string) {
    setStatus(stat);
  }

  useEffect(() => {
    if (!task || !task.id || status === task.status) {
      return;
    }
    apiRequest({
      whenAcquired: (accessToken: string) => {
        if (status) {
          updateTask(accessToken, task.id, status).then((task: Task) => {
            setLastModified(task.lastModified || "");
            queryClient
              .invalidateQueries({ queryKey: ["tasks"] })
              .then((r) => {});
          });
        }
      },
      authContext: authContext,
    }).then((r) => {});
  }, [authContext, status, task, setLastModified, queryClient]);

  useEffect(() => {
    if (!task || !task.id || !taskOwner || taskOwner!.owner === task.owner) {
      return;
    }
    apiRequest({
      whenAcquired: (accessToken: string) => {
        reassignTask(accessToken, task.id, taskOwner).then((task: Task) => {
          setLastModified(task.lastModified || "");
          queryClient
            .invalidateQueries({ queryKey: ["tasks"] })
            .then((r) => {});
        });
      },
      authContext: authContext,
    }).then((r) => {});
  }, [authContext, task, taskOwner, setLastModified, queryClient]);

  const items = [
    new DataItem("Priority", task?.priority),
    new DataItem("Code", task?.code),
    new DataItem("Patient", <PatientLink mrn={task?.forWhom} />),
  ];

  return (
    <div className="space-y-12 ">
      <div className=" pb-12 p-5 m-3 rounded-lg bg-blue-200 dark:bg-slate-500 shadow-sm ring-1 ring-gray-900/5">
        <p className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-50">
          {props.loading ? <Loading /> : task.description}
        </p>

        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="status"
              className="block text-sm font-medium leading-6 text-gray-400 dark:text-gray-50"
            >
              Status
            </label>
            <div className="mt-2">
              <select
                onChange={(e) => saveStatus(e.target.value)}
                id="status"
                name="status"
                defaultValue={status}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option value="requested">Requested</option>
                <option value="received">Received</option>
                <option value="accepted">Accepted</option>
                <option value="rejected">Rejected</option>
                <option value="in-progress">In-Progress</option>
                <option value="on-hold">On-Hold</option>
                <option value="failed">Failed</option>
                <option value="completed">Completed</option>
              </select>
            </div>
          </div>
        </div>

        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <h3 className="text-gray-400 dark:text-gray-50">Owner</h3>
            {isAdmin ? (
              <Assignment setTaskOwner={setTaskOwner} taskOwner={task?.owner} />
            ) : (
              <p className="mt-3 text-sm leading-6 text-gray-900 dark:text-gray-50">
                {task?.ownerName}
              </p>
            )}
          </div>

          {items.map((item) => (
            <div key={item.name} className="sm:col-span-4">
              <h3 className="text-gray-400 dark:text-gray-50">{item.name}</h3>
              <p className="mt-3 text-sm leading-6 text-gray-900 dark:text-gray-50">
                {item.value}
              </p>
            </div>
          ))}
          <div className="sm:col-span-4">
            <h3 className="text-gray-400 dark:text-gray-50">Last Modified</h3>
            <p className="mt-3 text-sm leading-6 text-gray-900 dark:text-gray-50">
              <Timestamp time={props.lastModified} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
