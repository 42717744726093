import { useGroupMembers } from "../../hooks/userHooks";
import React from "react";
import { TaskOwner } from "../../types/models";

export default function Assignment(props: {
  setTaskOwner: (
    value: ((prevState: TaskOwner | null) => TaskOwner) | TaskOwner
  ) => void;
  taskOwner: string;
}) {
  const members = useGroupMembers("6547ba39-5cc9-46ca-88ac-81a5edc96838");

  if (!members) {
    return <></>;
  }
  let memberIdToName = new Map(
    members.map<[string, string]>((user) => [user.id, user.displayName])
  );
  return (
    <select
      onChange={(e) =>
        props.setTaskOwner({
          owner: e.target.value,
          owner_name: memberIdToName.get(e.target.value) || "Unassigned",
        })
      }
      id="taskOwner"
      name="taskOwner"
      defaultValue={props.taskOwner}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
    >
      <option value="">Unassigned</option>
      {members.map((member) => (
        <option key={member.id} value={member.id}>
          {member.displayName}
        </option>
      ))}
    </select>
  );
}
