import { IMsalContext } from "@azure/msal-react/dist/MsalContext";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

export function authWrappedApi<T>(authContext: IMsalContext, apiFunc: any) {
  return async (): Promise<T> => {
    const accessTokenRequest = {
      scopes: ["api://3d0deb42-0540-4146-a883-89c160df146e/Patients.Read"],
      account: authContext.accounts[0],
    };
    if (authContext.inProgress === InteractionStatus.None) {
      const accessTokenResponse = await authContext.instance.acquireTokenSilent(
        accessTokenRequest
      );
      if (accessTokenResponse) {
        return await apiFunc(accessTokenResponse.accessToken);
      } else {
        throw new InteractionRequiredAuthError();
      }
    }
    throw new Error("Not authenticated");
  };
}

export function apiRequest(props: {
  whenAcquired: (accessToken: string) => void;
  authContext: IMsalContext;
  preValidator?: () => boolean;
}) {
  return (async () => {
    const accessTokenRequest = {
      scopes: ["api://3d0deb42-0540-4146-a883-89c160df146e/Patients.Read"],
      account: props.authContext.accounts[0],
    };
    if (props.preValidator && !props.preValidator()) {
      return;
    }
    if (props.authContext.inProgress === InteractionStatus.None) {
      props.authContext.instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          let accessToken = accessTokenResponse.accessToken;
          props.whenAcquired(accessToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            props.authContext.instance.acquireTokenRedirect(accessTokenRequest);
          }
        });
    }
  })();
}
