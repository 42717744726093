import { UITask } from "../hooks/taskHooks";
import TaskSwimlane from "../components/tasks/TaskSwimlane";
import { useMsal } from "@azure/msal-react";
import { useQuery } from "@tanstack/react-query";
import { findTasks } from "../services/taskApi";
import { authWrappedApi } from "../services/baseApi";

export default function Tasks() {
  const authContext = useMsal();
  const { isLoading, data } = useQuery({
    queryKey: ["tasks"],
    queryFn: authWrappedApi<UITask[]>(authContext, findTasks),
  });

  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-6">
      <TaskSwimlane tasks={data || []} loading={isLoading} />
    </div>
  );
}
