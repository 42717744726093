import patientClient from "../rc360-client";
import { Task, TaskOwner } from "../types/models";
import { UITask } from "../hooks/taskHooks";

export function findTasksForPatient(patientMrn: string | undefined) {
  return async (accessToken: string) => {
    if (!patientMrn) {
      return [];
    }
    const response = await patientClient.get(`/tasks`, {
      headers: {
        Authorization: `Bearer ${accessToken.trim()}`,
      },
    });
    return response.data.content
      .filter((task: Task) => {
        return task.patient_mrn === patientMrn;
      })
      .map((task: Task) => {
        return new UITask(task);
      });
  };
}

export async function findTasks(token: string): Promise<UITask[]> {
  const response = await patientClient.get(`/tasks`, {
    headers: {
      Authorization: `Bearer ${token.trim()}`,
    },
  });
  return response.data.content.map((task: Task) => {
    return new UITask(task);
  });
}

export async function reassignTask(
  token: string,
  taskId: string,
  taskOwner: TaskOwner
) {
  const response = await patientClient.post(
    `/tasks/${taskId}/reassign`,
    taskOwner,
    {
      headers: {
        Authorization: `Bearer ${token.trim()}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return response.data;
}

export async function updateTask(
  token: string,
  taskId: string,
  status: string
) {
  const response = await patientClient.patch(
    `/tasks/${taskId}`,
    { status: status },
    {
      headers: {
        Authorization: `Bearer ${token.trim()}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return response.data;
}

export async function appendNote(token: string, taskId: string, note: string) {
  const response = await patientClient.post(
    `/tasks/${taskId}/notes`,
    { note: note },
    {
      headers: {
        Authorization: `Bearer ${token.trim()}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return response.data;
}

export async function findTaskById(
  token: string,
  taskId: string
): Promise<any> {
  const response = await patientClient.get(`/tasks/${taskId}`, {
    headers: {
      Authorization: `Bearer ${token.trim()}`,
    },
  });
  return response.data;
}
