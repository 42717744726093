import { UITask } from "../../hooks/taskHooks";
import TaskLane from "./TaskLane";
import { DndContext, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { apiRequest } from "../../services/baseApi";
import { updateTask } from "../../services/taskApi";
import { useQueryClient } from "@tanstack/react-query";

export default function TaskSwimlane(props: {
  tasks: UITask[];
  loading: boolean;
}) {
  const [tasks, setTasks] = useState([] as UITask[]);
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const sensors = useSensors(mouseSensor);
  const authContext = useMsal();
  const queryClient = useQueryClient();

  useEffect(() => {
    setTasks(props.tasks);
  }, [props.tasks]);
  function handleDragEnd(event: any) {
    if (event.over) {
      apiRequest({
        whenAcquired: (accessToken: string) => {
          if (event.over.id) {
            updateTask(accessToken, event.active.id, event.over.id).then(() => {
              queryClient.invalidateQueries({ queryKey: ["tasks"] });
            });
          }
        },
        authContext: authContext,
      }).then((r) => {});

      setTasks(
        tasks.map((task) => {
          if (event.active.id === task.id) {
            task.status = event.over.id;
          }
          return task;
        })
      );
    }
  }

  function handleDragOver(event: any) {}

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
    >
      <div className="container mx-auto px-5 py-7 ">
        <div className="sticky top-0 z-40 flex min-h-full gap-x-6 px-4 py-4 shadow-sm sm:px-6 text-gray-900 dark:text-white">
          <TaskLane
            id="todo"
            name="TO-DO"
            tasks={tasks}
            sublanes={["accepted", "requested"]}
            loading={props.loading}
          />
          <TaskLane
            id="waiting"
            name="WAITING"
            tasks={tasks}
            sublanes={["received", "on-hold"]}
            loading={props.loading}
          />
          <TaskLane
            id="inprogress"
            name="IN-PROGRESS"
            tasks={tasks}
            sublanes={["in-progress"]}
            loading={props.loading}
          />
          <TaskLane
            id="done"
            name="DONE"
            tasks={tasks}
            sublanes={["completed", "rejected", "failed"]}
            loading={props.loading}
          />
        </div>
      </div>
    </DndContext>
  );
}
