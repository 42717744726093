import messageClient from "./messageClient";
import { Page, PatientMessage, PatientMessageList } from "../types/models";
import { AxiosResponse } from "axios";

export function findMessagesForPatient(
  patientMrn: string
): (accessToken: string) => Promise<PatientMessageList> {
  return async (accessToken): Promise<PatientMessageList> => {
    const response: AxiosResponse<
      Page<PatientMessage>,
      any
    > = await messageClient.get(`/patients/${patientMrn}/messages`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Practice: localStorage.getItem("selectedPractice"),
      },
    });
    return { content: response.data.content.reverse() };
  };
}

export async function sendPatientMessage(
  accessToken: string,
  message: PatientMessage
): Promise<PatientMessageList> {
  const response = await messageClient.post(`/messages`, message, {
    headers: {
      Authorization: `Bearer ${accessToken.trim()}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.data;
}
