import PatientName from "../widgets/PatientName";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { CompositePatient, Patient } from "../../types/models";
import { useMsal } from "@azure/msal-react";
import { apiRequest } from "../../services/baseApi";
import {
  downloadPatientPdf,
  practiceSelected,
} from "../../services/patientapi";
import {
  ChatBubbleLeftIcon,
  CheckCircleIcon,
  CloudArrowDownIcon,
} from "@heroicons/react/20/solid";
import BasicButton from "../widgets/BasicButton";
import Drawer from "../widgets/Drawer";
import Messages from "../messages/Messages";
import PatientTaskList from "../tasks/PatientTaskList";
import Loading from "../widgets/Loading";

export default function HorizontalPatientDisplay(props: {
  patientDashboard: CompositePatient | undefined;
  loading: boolean;
}) {
  const authContext = useMsal();
  const [downloading, setDownloading] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const [showMessagesEnabled, setShowMessagesEnabled] = useState(
    !!props.patientDashboard?.patient?.phone_cell
  );
  useEffect(() => {
    setShowMessagesEnabled(!!props.patientDashboard?.patient?.phone_cell);
  }, [setShowMessagesEnabled, props.patientDashboard]);

  const downloadEnabled = true;
  const patient: Patient | undefined = props.patientDashboard?.patient;
  const patientStats = [
    { name: "MRN", value: patient?.mrn },
    { name: "Sex", value: patient?.gender },
    { name: "Age", value: patient?.age },
    { name: "Height", value: patient?.height || "--" },
    { name: "Date of Birth", value: patient?.dob },
  ];
  const patientDiagnosis = [
    {
      name: "Risk Score",
      value: patient?.current_risk_score,
    },
    {
      name: "Insurance",
      value: patient?.primary_insurance_plan
        ? patient.primary_insurance_plan
        : "None",
    },
    { name: "Diagnosed Stage", value: patient?.current_ckd_stage },
    { name: "Predicted Stage", value: patient?.last_ckd_diagnosis },
  ];

  function navigateToMessages(event: any) {
    event.preventDefault();
    if (showTasks) {
      setShowTasks(false);
    }
    setShowMessages(true);
  }

  function navigateToTasks(event: any) {
    event.preventDefault();
    if (showMessages) {
      setShowMessages(false);
    }
    setShowTasks(true);
  }

  const loadPatient: MouseEventHandler<HTMLButtonElement> = (event: any) => {
    event.preventDefault();
    setDownloading(true);
    (async () => {
      apiRequest({
        whenAcquired: (accessToken: string) => {
          downloadPatientPdf(patient!.id, accessToken, setDownloading);
        },
        authContext: authContext,
        preValidator: practiceSelected,
      }).then((r) => {});
    })();
  };

  return (
    <div className="rounded-lg bg-blue-200 dark:bg-slate-600 shadow-sm ring-1 ring-gray-900/5">
      <Drawer
        title={"Messages"}
        showDrawer={showMessages}
        setShowDrawer={setShowMessages}
      >
        <Messages patientId={patient?.id!} patientCell={patient?.phone_cell} />
      </Drawer>
      <Drawer title="Tasks" showDrawer={showTasks} setShowDrawer={setShowTasks}>
        <div className="ml-8 mr-8 mt-10">
          <PatientTaskList patientId={patient?.mrn} />
        </div>
      </Drawer>
      <div className="mt-6 gap-x-4 border-t border-gray-900/5 px-6 pb-6">
        <div className="md:flex md:items-center md:justify-between py-10">
          <div className="min-w-0 flex-1">
            <h2 className="font-bold leading-7 text-5xl print:text-lg text-gray-900 dark:text-gray-50">
              {props.loading ? <Loading /> : <PatientName patient={patient!} />}
            </h2>
          </div>
          <div className="mt-4 flex md:ml-4 md:mt-0">
            <div>
              <BasicButton
                onClick={navigateToMessages}
                disabled={!showMessagesEnabled}
              >
                <ChatBubbleLeftIcon
                  aria-hidden="true"
                  className="-ml-1 mr-1 h-5 w-5"
                />{" "}
                Messages
              </BasicButton>
            </div>
            <div>
              <BasicButton onClick={navigateToTasks}>
                <CheckCircleIcon
                  aria-hidden={"true"}
                  className={"-ml-1 mr-1 h-5 w-5"}
                />
                Tasks
              </BasicButton>
            </div>

            <div className={downloadEnabled ? "" : "hidden"}>
              <button
                disabled={downloading}
                type="button"
                onClick={loadPatient}
                className="ml-3 inline-flex items-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                {downloading ? (
                  <>
                    Downloading <Loading />
                  </>
                ) : (
                  <>
                    <CloudArrowDownIcon
                      aria-hidden="true"
                      className="-ml-1 mr-1 h-5 w-5"
                    />
                    Download
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        <dl className="grid grid-cols-5 mb-5">
          {patientStats.map((stat: any) => (
            <div>
              <dt className="print:font-medium screen:font-bold py-1 text-gray-400 dark:text-gray-50">
                {stat.name}
              </dt>
              <dd className="leading-6 text-gray-900 dark:text-gray-50">
                {stat.value}
              </dd>
            </div>
          ))}
        </dl>
        <dl className="grid grid-cols-4">
          {patientDiagnosis.map((stat: any) => (
            <div>
              <dt className="print:font-medium screen:font-bold py-1 text-gray-400 dark:text-gray-50">
                {stat.name}
              </dt>
              <dd className="leading-6 text-gray-900 dark:text-gray-50">
                {stat.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
