import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import PatientList from "../components/patients/PatientList";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { usePlans } from "../hooks/planHooks";
import { useQuery } from "@tanstack/react-query";
import { authWrappedApi } from "../services/baseApi";
import { Page, Patient } from "../types/models";
import { useMsal } from "@azure/msal-react";
import { findPatients, FindPatientsParams } from "../services/patientapi";
import { SearchContext } from "../components/layout/AppLayout";

export default function Patients() {
  const [practiceName, , setSearchContext] = useOutletContext<any[]>();
  const [sorting, setSorting] = useState<SortingState>([
    { id: "current_risk_score", desc: true },
  ]);
  const searchContext = useContext(SearchContext);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const authContext = useMsal();
  const createFindPatientsQuery = (): FindPatientsParams => {
    return {
      filterBy: columnFilters
        .map((columnFilter) => {
          return columnFilter.id;
        })
        .join(","),
      filterValues: columnFilters
        .map((columnFilter) => {
          return columnFilter.value;
        })
        .join(","),
      sortBy: sorting && sorting.length > 0 ? sorting[0].id : null,
      direction:
        sorting && sorting.length > 0
          ? sorting[0].desc
            ? "desc"
            : "asc"
          : null,
      searchBy: searchContext,
      pageSize: pagination.pageSize,
      page: pagination.pageIndex,
    };
  };
  const patient: Patient = {
    id: "",
    mrn: "",
    dob: " ",
    age: 0,
    ethnicity: "",
    gender: "Female",
    race: " ",
    zip: " ",
    date_of_death: " ",
    deceased_flag: " ",
    confidential: " ",
    first_name: " ",
    last_name: " ",
    address1: " ",
    city: " ",
    state: " ",
    middle_name: " ",
    current_payer: " ",
    last_ckd_diagnosis: " ",
  };
  const { data, isLoading, isPlaceholderData } = useQuery({
    queryKey: [
      "patients",
      practiceName,
      columnFilters,
      sorting,
      pagination.pageSize,
      pagination.pageIndex,
      searchContext,
    ],
    placeholderData: {
      content: Array.from({ length: 10 }, () => ({ ...patient })),
      total_count: 0,
    },
    queryFn: authWrappedApi<Page<Patient>>(
      authContext,
      (token: string): Promise<Page<Patient>> => {
        return findPatients(token, createFindPatientsQuery());
      }
    ),
  });
  const plansForPractice = usePlans(practiceName);

  // reset pagination when practice changes
  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [practiceName, setPagination]);

  const loading = isLoading || !data || isPlaceholderData;

  return (
    <PatientList
      loading={loading}
      patients={data ? data!.content! : []}
      plansForPractice={plansForPractice}
      setSorting={setSorting}
      sorting={sorting}
      pagination={pagination}
      setPagination={setPagination}
      rowCount={data ? data.total_count : 0}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      setSearchContext={setSearchContext}
    />
  );
}
