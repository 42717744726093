import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { apiRequest } from "../services/baseApi";
import { practiceSelected } from "../services/patientapi";
import { findTaskById } from "../services/taskApi";
import { Task, TaskAnnotation } from "../types/models";

export class UITask {
  id: string;
  description: string;
  status: string;
  fullStatus: string;
  dueDate: string;
  code: string;
  statusGroup: "TO-DO" | "WAITING" | "IN-PROGRESS" | "DONE";
  priority: string;
  priorityBackgroundColor: string;
  priorityTextColor: string;
  businessStatus: string;
  forWhom: string;
  statusReason: string;
  notes: TaskAnnotation[];
  owner: string;
  ownerName: string;
  lastModified: string;
  authoredOn: string;

  constructor(task: Task) {
    this.id = task.id;
    this.owner = task.owner!;
    this.ownerName = task.owner_name!;
    this.code = task.code!;
    this.priority = task.priority || "";
    this.statusReason = task.status_reason || "";
    this.forWhom = task.patient_mrn || "";
    this.lastModified = task.modified_on || "";
    this.authoredOn = task.authored_on || "";
    switch (this.priority) {
      case "stat":
      case "urgent":
      case "high":
        this.priorityBackgroundColor = "bg-red-100 dark:bg-red-800";
        this.priorityTextColor = "text-red-700 dark:text-red-100";
        break;
      case "asap":
      case "medium":
        this.priorityBackgroundColor = "bg-orange-100 dark:bg-orange-700";
        this.priorityTextColor = "text-orange-700 dark:text-orange-100";
        break;
      default:
        this.priorityBackgroundColor = "bg-green-100 dark:bg-green-700";
        this.priorityTextColor = "text-green-700 dark:text-green-100";
    }
    this.description = task.description || "";
    this.status = task.status!;
    this.notes = task.notes || [];
    this.businessStatus = this.fullStatus = "";
    this.dueDate = "";
    if (task.due_date) {
      this.dueDate = new Date(task.due_date!).toDateString();
    }
    this.statusGroup = "TO-DO";
    switch (task.status) {
      case "draft":
      case "requested":
      case "accepted":
        this.statusGroup = "TO-DO";
        break;
      case "rejected":
      case "failed":
      case "completed":
      case "cancelled":
      case "entered-in-error":
        this.statusGroup = "DONE";
        break;
      case "received":
      case "on-hold":
        this.statusGroup = "WAITING";
        break;
      default:
        this.statusGroup = "IN-PROGRESS";
        break;
    }
  }
}

export function useTask(
  taskId: string | undefined,
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void
) {
  const authContext = useMsal();
  const [getTask, setTask] = useState<UITask>();
  if (!taskId) {
    throw new Error("Task ID not specified");
  }

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        findTaskById(accessToken, taskId!).then((task: Task) => {
          setLoading(false);
          setTask(new UITask(task));
        });
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {});
  }, [authContext, setTask, setLoading, taskId]);
  return getTask;
}
