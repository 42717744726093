import React from "react";
import Timestamp from "../widgets/Timestamp";
import { PatientMessage } from "../../types/models";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Message({ message }: { message: PatientMessage }) {
  return (
    <div className={classNames("mt-5 mb-5")}>
      <div className="sm:flex">
        <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
          <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 200 200"
            preserveAspectRatio="none"
            aria-hidden="true"
            className="h-16 w-16 border border-gray-300 bg-white dark:bg-gray-500 text-gray-300"
          >
            <path
              d="M0 0l200 200M0 200L200 0"
              strokeWidth={1}
              vectorEffect="non-scaling-stroke"
            />
          </svg>
        </div>
        <div
          className={
            message.in_process
              ? "text-gray-500"
              : "text-gray-900 dark:text-gray-100"
          }
        >
          <h4 className="">
            <span className={"text-lg font-bold"}>
              {message.sent_by_name ? message.sent_by_name : "Agent"}
            </span>{" "}
            <span className={"text-sm font-light"}>
              <Timestamp time={message.date_sent} />
            </span>
          </h4>
          <p
            className={classNames(
              "mt-1",
              message.in_process
                ? "text-gray-500"
                : "text-gray-900 dark:text-gray-100"
            )}
          >
            {message.raw_message}
          </p>
        </div>
      </div>
    </div>
  );
}
