import { Vitals } from "../../types/models";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function VitalsGraphs(props: {
  vitals: Vitals | undefined;
  containerClassName: string;
  graphWidth?: number | string | undefined;
  printMode?: boolean;
}) {
  const graphWidth: string | number | undefined = props.graphWidth || "95%";
  const vitals = props.vitals || { systolic: [], diastolic: [], weight: [] };
  const weightUnit = vitals.weight_unit ? ` in ${vitals.weight_unit}` : "";

  return (
    <>
      <div className={props.containerClassName}>
        <div className="mt-5">
          <div className="relative">
            <LazyLoadImage
              src={`/icons/svg/outline/devices/blood_pressure.svg`}
              width="32"
              height="32"
              alt={`blood pressure outline icon`}
              effect="opacity"
              className="absolute left-6 inset-y"
            />
            <h2 className="screen:font-large screen:font-bold screen:text-xl print:font-medium text-gray-500 dark:text-white pl-10 pb-2 ml-5">
              Blood Pressure
            </h2>
          </div>

          <ResponsiveContainer
            width={graphWidth}
            height={400}
            debounce={1}
            minWidth={100}
            minHeight={100}
          >
            <LineChart
              width={200}
              height={400}
              syncId="selectedDate"
              syncMethod="value"
            >
              <Legend wrapperStyle={{ bottom: 0 }} />
              <CartesianGrid stroke="#ccc" />
              <Tooltip />
              <XAxis
                dataKey="d"
                allowDuplicatedCategory={false}
                tickMargin={10}
              />
              <YAxis dataKey="v" domain={[0, 200]} />
              <Line
                type="monotone"
                dataKey="v"
                stroke={props.printMode ? "#999" : "#8884d8"}
                strokeWidth="3"
                data={vitals.systolic}
                name="systolic"
                key="systolic"
              />
              <Line
                type="linear"
                dataKey="v"
                stroke={props.printMode ? "#ccc" : "#880000"}
                strokeWidth="3"
                data={vitals.diastolic}
                name="diastolic"
                key="diastolic"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className={props.containerClassName}>
        <div className="mt-5">
          <div className="relative">
            <LazyLoadImage
              src={`/icons/svg/outline/devices/weight.svg`}
              width="32"
              height="32"
              alt={`weight outline icon`}
              effect="opacity"
              className="absolute left-6 inset-y"
            />
            <h2 className="screen:font-large screen:font-bold screen:text-xl print:font-medium text-gray-500 dark:text-white pl-10 pb-2 ml-5">
              Weight
            </h2>
          </div>
          <ResponsiveContainer
            width={graphWidth}
            height={400}
            debounce={1}
            minWidth={100}
            minHeight={100}
          >
            <LineChart
              width={200}
              height={400}
              syncId="selectedDate"
              syncMethod="value"
            >
              <Legend wrapperStyle={{ bottom: 0 }} />
              <CartesianGrid stroke="#ccc" />
              <Tooltip />
              <XAxis
                dataKey="d"
                allowDuplicatedCategory={false}
                tickMargin={10}
              />
              <YAxis dataKey="v" />
              <Line
                type="monotone"
                dataKey="v"
                stroke={props.printMode ? "#999" : "#8884d8"}
                strokeWidth="3"
                data={vitals.weight}
                name={`Weight${weightUnit}`}
                key="weight"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
}
