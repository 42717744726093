// noinspection JSIgnoredPromiseFromCall

import React, { useEffect, useRef, useState } from "react";
import Message from "./Message";
import { PatientMessage, PatientMessageList } from "../../types/models";
import { apiRequest, authWrappedApi } from "../../services/baseApi";
import {
  findMessagesForPatient,
  sendPatientMessage,
} from "../../services/messagesApi";
import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loading from "../widgets/Loading";

interface MessagesProps {
  patientId: string;
  patientCell: string | undefined;
}

function createPatientMessage(
  message: Partial<PatientMessage>
): PatientMessage {
  return {
    date_sent: new Date().toISOString(),
    patient_mrn: message.patient_mrn!,
    message_type: "custom",
    phone_cell: message.phone_cell!,
    raw_message: message.raw_message!,
    in_process: true,
    sent_by_id: message.sent_by_id!,
    sent_by_name: message.sent_by_name!,
  };
}

export default function Messages({ patientId, patientCell }: MessagesProps) {
  const [message, setMessage] = useState("");
  const [localMessages, setLocalMessages] = useState<PatientMessage[]>([]);
  const authContext = useMsal();
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const { isLoading, data } = useQuery({
    queryKey: ["messagesForPatient", patientId],
    queryFn: authWrappedApi<PatientMessageList>(
      authContext,
      findMessagesForPatient(patientId)
    ),
  });
  const activeAccount = authContext.instance.getActiveAccount();
  const newMessage = useMutation({
    mutationFn: (message: PatientMessage): any => {
      return apiRequest({
        whenAcquired: (accessToken: string) => {
          sendPatientMessage(accessToken, message);
        },
        authContext: authContext,
      });
    },
    onSuccess: (data) => {
      // this logic is kinda incorrect
      const messages = localMessages.map((message) => {
        message.in_process = false;
        return message;
      });
      setLocalMessages(messages);
    },
  });

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [data]);

  function detectEnter(event: any) {
    if (event.code === "Enter" && !event.shiftKey) {
      event.preventDefault();
      const message = createPatientMessage({
        patient_mrn: patientId,
        phone_cell: patientCell!,
        raw_message: event.target.value,
        sent_by_id: activeAccount!.localAccountId,
        sent_by_name: activeAccount!.name,
      });
      setLocalMessages([...localMessages, message]);
      newMessage.mutate(message);
      setMessage("");
    }
  }

  return (
    <div className="ml-8 mr-8 mt-10">
      <div>
        {isLoading || !data ? (
          <Loading />
        ) : (
          data.content.map((message, index) => (
            <Message key={index} message={message} />
          ))
        )}
        {localMessages.map((message, index) => (
          <Message key={index} message={message} />
        ))}
        <div ref={bottomRef}></div>
      </div>
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
        <div className="col-span-full">
          <div className="mt-2">
            <textarea
              name="sendMessage"
              placeholder="Type here to send a message"
              rows={4}
              onKeyUp={detectEnter}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
