import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import React from "react";

interface SearchBarProps {
  setSearchContext: React.Dispatch<React.SetStateAction<string | null>>;
  searchContext: string | null;
}

export default function SearchBar({
  setSearchContext,
  searchContext,
}: SearchBarProps) {
  function performSearch(event: any) {
    if (event.key === "Enter") {
      setSearchContext(event.target.value);
    }
  }

  return (
    <div className="ml-3 sticky top-0 z-40 flex h-8 shrink-0 items-center rounded-md gap-x-6 border-b border-white/5 bg-gray-900 px-4 shadow-sm lg:w-7/12 sm:px-6 lg:px-6">
      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-8">
        <div className="flex flex-1">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <MagnifyingGlassIcon
              className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500"
              aria-hidden="true"
            />
            <input
              id="search-field"
              className="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 text-white focus:ring-0 sm:text-sm"
              placeholder="Search..."
              type="search"
              name="search"
              onKeyDown={performSearch}
              defaultValue={searchContext!}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
