import React from "react";
import { useParams } from "react-router-dom";
import PatientDashboard from "../components/patient/PatientDashboard";
import { useQuery } from "@tanstack/react-query";
import { authWrappedApi } from "../services/baseApi";
import { useMsal } from "@azure/msal-react";
import { CompositePatient } from "../types/models";
import { findPatientDashboardByMrn } from "../services/patientapi";

export default function PatientDetail() {
  const { patientId } = useParams();
  const authContext = useMsal();
  const { isLoading, data } = useQuery({
    queryKey: ["patientDashboard", patientId],
    queryFn: authWrappedApi<CompositePatient>(
      authContext,
      async (token: string) => {
        if (!patientId) {
          throw new Error("No patient id");
        }
        return await findPatientDashboardByMrn(patientId, token);
      }
    ),
  });

  return (
    <PatientDashboard
      loading={isLoading}
      patientId={patientId}
      patientDashboard={data}
    />
  );
}
