import React, { useState } from "react";
import { UITask } from "../../hooks/taskHooks";
import NoteWidget from "../notes/NoteWidget";
import TaskDetails from "./TaskDetails";

export default function TaskDashboard(props: {
  task: UITask | undefined;
  taskId: string;
  loading: boolean;
}) {
  const [lastModified, setLastModified] = useState(props.task?.lastModified);
  return (
    <div className="container mx-auto px-5 py-7">
      <form>
        <div className="flex">
          <div className="flex-none w-96">
            <TaskDetails
              task={props.task}
              setLastModified={setLastModified}
              lastModified={lastModified}
              loading={props.loading}
            />
          </div>
          <div className="flex-auto">
            <NoteWidget
              task={props.task}
              setLastModified={setLastModified}
              loading={props.loading}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
