import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import {
  findLabDataByLookupCode,
  findLabNames,
  findLabSummaries,
  findPatientStatsByMrn,
  practiceSelected,
} from "../services/patientapi";
import { DataSeries, Lab, LabResult } from "../types/models";
import { apiRequest } from "../services/baseApi";

export interface LabData {
  labCategories: string[];
  labTypes: Lab[];
}

export function useSeriesData(patientId: string, statName: string) {
  const authContext = useMsal();
  const [rows, setRows] = useState([] as DataSeries[]);

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        findPatientStatsByMrn(patientId, accessToken, statName).then((data) => {
          setRows(data);
        });
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then(() => {});
  }, [authContext, patientId, statName]);
  return rows;
}

export function useLabResults(
  patientId: string | undefined,
  selectedLab: Lab | null
): LabResult[] {
  const authContext = useMsal();
  const [labResults, setLabResults] = useState([] as LabResult[]);
  useEffect(() => {
    if (!patientId) {
      return;
    }
    apiRequest({
      whenAcquired: (accessToken: string) => {
        if (selectedLab) {
          findLabDataByLookupCode(
            patientId,
            selectedLab.lookup_code,
            accessToken
          ).then((data) => {
            setLabResults(data);
          });
          return;
        }
        setLabResults([]);
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {});
  }, [authContext, selectedLab, patientId]);

  return labResults;
}

export function useLabData(patientId: string | undefined): LabData {
  const [labCategories, setLabCategories] = useState([] as string[]);
  const [labTypes, setLabTypes] = useState([] as Lab[]);
  const authContext = useMsal();
  useEffect(() => {
    if (!patientId) {
      return;
    }
    apiRequest({
      whenAcquired: (accessToken: string) => {
        findLabNames(patientId!, accessToken).then((labNames: Lab[]) => {
          const categories = new Set();
          labNames.forEach((lab: Lab) => {
            categories.add(lab.category);
          });
          setLabCategories(Array.from(categories.keys()) as string[]);
          setLabTypes(labNames);
        });
      },
      authContext: authContext,
      preValidator: practiceSelected,
    });
  }, [authContext, patientId]);

  return { labCategories, labTypes };
}

export function useLabSummaries(patientId: string | undefined) {
  const authContext = useMsal();
  const [summary, setSummary] = useState({
    summaries: [],
    encounter_dates: [],
  });

  useEffect(() => {
    if (!patientId) {
      return;
    }

    apiRequest({
      whenAcquired: (accessToken: string) => {
        const bgColors = [
          "bg-blue-300 dark:bg-blue-900",
          "bg-emerald-300 dark:bg-emerald-900",
          "bg-violet-300 dark:bg-violet-900",
          "bg-sky-300 dark:bg-sky-900",
          "bg-lime-300 dark:bg-lime-900",
          "bg-grey-300 dark:bg-grey-900",
          "bg-teal-300 dark:bg-teal-900",
        ];

        findLabSummaries(patientId, accessToken).then((data) => {
          let lastCategory: string | null = null;
          let categoryIndex = -1;
          data.summaries.forEach((summary: any) => {
            if (summary.category !== lastCategory) {
              categoryIndex++;
              lastCategory = summary.category;
            }
            if (categoryIndex >= bgColors.length) {
              categoryIndex = 0;
            }
            summary.color = bgColors[categoryIndex];
          });
          setSummary(data);
        });
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {});
  }, [authContext, patientId]);
  return summary;
}
