import { UITask } from "../../hooks/taskHooks";
import TaskCard from "./TaskCard";
import { useQuery } from "@tanstack/react-query";
import { authWrappedApi } from "../../services/baseApi";
import { findTasksForPatient } from "../../services/taskApi";
import { useMsal } from "@azure/msal-react";
import Loading from "../widgets/Loading";

interface PatientTaskListProps {
  patientId?: string;
}

export default function PatientTaskList({ patientId }: PatientTaskListProps) {
  const authContext = useMsal();
  const { isLoading, data } = useQuery({
    queryKey: ["tasksForPatient", patientId],
    queryFn: authWrappedApi<UITask[]>(
      authContext,
      findTasksForPatient(patientId)
    ),
  });
  const tasks = data;
  if (!tasks || tasks.length === 0) {
    return (
      <div className={"text-gray-900 dark:text-gray-50"}>
        {isLoading ? <Loading /> : "No tasks found"}
      </div>
    );
  }
  return (
    <div>
      {tasks.map((task: UITask) => (
        <div>
          <TaskCard task={task} />
        </div>
      ))}
    </div>
  );
}
