import React from "react";
import { BarLoader } from "react-spinners";

export default function Loading(props: { size?: number }) {
  const darkMode =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  return (
    <BarLoader
      aria-label="Loading..."
      height={props.size || 15}
      color={darkMode ? "white" : "black"}
      loading={true}
    />
  );
}
